import { DEFAULT_CURRENCY_CODE, ErrorHandler, NgModule, LOCALE_ID, Type } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GraphQLModule } from './graphql/graphql.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import localeDE from '@angular/common/locales/de';
import localeDEExtra from '@angular/common/locales/extra/de';
import { MatDateFnsModule } from "@angular/material-date-fns-adapter";
import { GlobalErrorHandler } from "./misc/global-error-handler";
import { ServerErrorInterceptor } from "./interceptors/server-error.interceptor";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { DynamicFormControl, DYNAMIC_FORM_CONTROL_MAP_FN, DynamicFormControlModel, DYNAMIC_MATCHER_PROVIDERS } from "@ng-dynamic-forms/core";
import {
  DynamicEntityAutocompleteComponent,
  DYNAMIC_FORM_CONTROL_TYPE_ENTITY_AUTOCOMPLETE,
  DYNAMIC_FORM_CONTROL_TYPE_ENTITY_SELECT,
  DynamicEntitySelectComponent,
  EntitySelectorModule,
  WizardModule
} from "@pd/ngx-components";
import { MAT_TABS_CONFIG } from "@angular/material/tabs";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { de } from 'date-fns/locale';
import { MatDialogModule } from "@angular/material/dialog";

registerLocaleData(localeDE, 'de-DE', localeDEExtra);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    GraphQLModule,
    HttpClientModule,
    MatDateFnsModule,
    MatSnackBarModule,
    MatDialogModule, // Added related to usage in WizardConfigurationService
    EntitySelectorModule,
    WizardModule
  ],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true},
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: MAT_DATE_LOCALE, useValue: de },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
    {
      provide: DYNAMIC_FORM_CONTROL_MAP_FN,
      useValue: (model: DynamicFormControlModel): Type<DynamicFormControl> | null  => {
        switch (model.type) {
          case DYNAMIC_FORM_CONTROL_TYPE_ENTITY_AUTOCOMPLETE:
            return DynamicEntityAutocompleteComponent;
          case DYNAMIC_FORM_CONTROL_TYPE_ENTITY_SELECT:
            return DynamicEntitySelectComponent;

          default:
            return null;

        }
      }
    },
    DYNAMIC_MATCHER_PROVIDERS,
    { provide: MAT_TABS_CONFIG, useValue: {animationDuration: '0ms', stretchTabs: false} }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
